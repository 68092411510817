import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {PageEntry} from '../../../core/page/page.entry';
import {Pageable} from '../../../core/page/pageable';
import {PageRestUtils} from '../../../core/page/commons/page-rest.utils';
import {VehiclesFilter} from './vehicles-list/vehicles.filter';
import {VehicleEntry} from './vehicles-list/vehicle.entry';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HttpUtils} from '../../../core/page/commons/http.utils';
import {VehicleParametersForm} from './vehicles-list/vehicle-info/vehicle-parameters/model/vehicle-parameters.component';
import {VehicleActionsEntry} from './vehicles-list/vehicle-info/vehicle-actions/model/vehicle-actions.entry';
import {VehicleNotesEntry} from './vehicles-list/vehicle-info/vehicle-notes/model/vehicle-notes.entry';
import {TempIdChangeEntry} from './vehicles-list/vehicle-info/temp-id-change/model/temp-id-change.entry';
import {VehicleData} from './vehicles-list/vehicle-data';
import {LatestVehicleEntry} from '../../../core/component/latest-vehicles-info/model/latest-vehicle-entry';
import {DriverNotificationsEntry} from './drivers-notifications/model/driver-notifications.entry';
import {DriverNotificationsForm} from './drivers-notifications/model/driver-notifications.form';
import {VehicleInventoryForm} from './vehicles-list/vehicle-info/vehicle-inventory/model/vehicle-inventory.form';
import {InventoryItemForm} from './vehicles-list/vehicle-info/vehicle-inventory/model/inventory-item.form';
import {AvailableMapEntry} from './vehicles-list/vehicle-info/navigation/model/available-map.entry';
import {
  TabClientConfigurationEntry
} from './vehicles-list/vehicle-info/tab-configuration/model/tab-client-configuration.entry';
import {PaymentCardForm} from './vehicles-list/vehicle-info/vehicle-payment-card/model/payment-card.form';
import {PaymentCardEntry} from './vehicles-list/vehicle-info/vehicle-payment-card/model/payment-card.entry';
import {ShellCardResponse} from './vehicles-list/vehicle-info/vehicle-payment-card/model/shell-card.response';
import {ShellCardEntry} from './vehicles-list/vehicle-info/vehicle-payment-card/model/shell-card.entry';
import {TellTaleEntry} from './vehicle-dashboard/model/tell-tale.entry';

@Injectable()
export class VehiclesService {

  private updateNoteStatusSource = new Subject<any>();
  private updateNoteStatus$ = this.updateNoteStatusSource.asObservable();

  constructor(private http: HttpClient) {
  }

  public fetchVehicles(filter: VehiclesFilter, pageable?: Pageable): Observable<PageEntry<VehicleEntry>> {
    let params = PageRestUtils.appendPageableParams(pageable, new HttpParams());
    params = HttpUtils.appendParam(params, 'plateNumber', filter.plateNumber);
    let simNr = filter.simNumber && filter.simNumber.includes('+')
      ? filter.simNumber.replace('+', '%2B')
      : filter.simNumber;
    params = HttpUtils.appendParam(params, 'simNumber', simNr);
    params = HttpUtils.appendParam(params, 'vehicleType', filter.vehicleType);
    params = HttpUtils.appendParam(params, 'licensePlateNumber', filter.licensePlateNumber);
    params = HttpUtils.appendParam(params, 'vinCode', filter.vinCode);
    params = HttpUtils.appendParam(params, 'imei', filter.imei);
    params = HttpUtils.appendParam(params, 'physicalImei', filter.physicalImei);
    params = HttpUtils.appendParam(params, 'vinEmpty', filter.vinEmpty);
    params = HttpUtils.appendParam(params, 'licenseEmpty', filter.licenseEmpty);
    params = HttpUtils.appendParam(params, 'department', filter.department);
    params = HttpUtils.appendParam(params, 'vehiclesWithDevices', filter.vehiclesWithDevices);
    params = HttpUtils.appendParam(params, 'vehiclesWithoutDevices', filter.vehiclesWithoutDevices);

    return this.http.get<PageEntry<VehicleEntry>>('/api/vehicles', {params: params});
  }

  public getVehiclesExcel(filter: VehiclesFilter, currentLang: string): Observable<Blob> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'plateNumber', filter.plateNumber);
    let simNr = filter.simNumber && filter.simNumber.includes('+')
      ? filter.simNumber.replace('+', '%2B')
      : filter.simNumber;
    params = HttpUtils.appendParam(params, 'simNumber', simNr);
    params = HttpUtils.appendParam(params, 'vehicleType', filter.vehicleType);
    params = HttpUtils.appendParam(params, 'licensePlateNumber', filter.licensePlateNumber);
    params = HttpUtils.appendParam(params, 'vinCode', filter.vinCode);
    params = HttpUtils.appendParam(params, 'imei', filter.imei);
    params = HttpUtils.appendParam(params, 'vinEmpty', filter.vinEmpty);
    params = HttpUtils.appendParam(params, 'licenseEmpty', filter.licenseEmpty);
    params = HttpUtils.appendParam(params, 'department', filter.department);
    params = HttpUtils.appendParam(params, 'vehiclesWithDevices', filter.vehiclesWithDevices);
    params = HttpUtils.appendParam(params, 'vehiclesWithoutDevices', filter.vehiclesWithoutDevices);
    params = HttpUtils.appendParam(params, 'locale', currentLang);

    return this.http.get('/api/vehicles/excel', {responseType: 'blob', params: params});
  }

  public updateDriver(vehicleId: number, driverId: number): Observable<any> {
    return this.http.put(`api/vehicles/${vehicleId}/${driverId}`, {});
  }

  public removeDriverFromVehicle(vehicleId: number): Observable<void> {
    return this.http.put<void>(`api/vehicles/${vehicleId}/remove-driver`, {});
  }

  public updateDepartment(vehicleId: number, departmentId: number): Observable<any> {
    return this.http.put(`api/vehicles/department/${vehicleId}/${departmentId}`, {});
  }

  public checkUniquePlateNumber(vehicleId: number, plateNumber: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/vehicles/check-plate-number?vehicle=${vehicleId}&plateNumber=${plateNumber}`, {});
  }

  public checkUniqueLicensePlateNumber(vehicleId: number, plateNumber: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/vehicles/check-license-plate-number?vehicle=${vehicleId}&plateNumber=${plateNumber}`, {});
  }

  public checkUniquePhoneNumber(vehicleId: number, phoneNumber: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/vehicles/check-phone-number?vehicle=${vehicleId}&phoneNumber=${phoneNumber}`, {});
  }

  public savePlateNumber(vehicleId: number, plateNumber: string): Observable<any> {
    return this.http.put(`api/vehicles/plate-change?vehicle=${vehicleId}&plateNumber=${plateNumber}`, {}, {responseType: 'text'});
  }

  public saveLicensePlate(vehicleId: number, plateNumber: string): Observable<any> {
    return this.http.put(`api/vehicles/license-plate-change?vehicle=${vehicleId}&plateNumber=${plateNumber}`, {}, {responseType: 'text'});
  }

  public checkUniqueVinCode(vehicleId: number, vinCode: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/vehicles/check-vin-code?vehicleId=${vehicleId}&vinCode=${vinCode}`, {});
  }

  public saveVinCode(vehicleId: number, vinCode: string): Observable<string> {
    return this.http.put(`api/vehicles/vin-code-change?vehicle=${vehicleId}&vinCode=${vinCode}`, {}, {responseType: 'text'})
  }

  public saveFuelCoefficient(vehicleId: number, coefficient: number | string): Observable<string> {
    return this.http.put(`api/vehicles/fuel-coefficient?vehicle=${vehicleId}&coefficient=${coefficient}`, {}, {responseType: 'text'})
  }

  public saveTrait(vehicleId: number, vehicleType: string): Observable<any> {
    return this.http.put(`api/vehicles/trait-change?vehicle=${vehicleId}&vehicleType=${vehicleType}`, {}, {responseType: 'text'});
  }

  public savePhoneNumber(vehicleId: number, phoneNumber: string): Observable<any> {
    return this.http.put('api/vehicles/phone-change/' + phoneNumber + `?vehicle=${vehicleId}`, {}, {responseType: 'text'});
  }

  public saveLowTemperature(vehicleId: number, input: string): Observable<any> {
    return this.http.put('api/vehicles/low-temperature-change/' + vehicleId + `?lowTemperature=${input}`, {}, {responseType: 'text'});
  }

  public saveHighTemperature(vehicleId: number, input: string): Observable<any> {
    return this.http.put('api/vehicles/high-temperature-change/' + vehicleId + `?highTemperature=${input}`, {}, {responseType: 'text'});
  }

  public saveSecondCompartmentLowTemperature(vehicleId: number, input: string): Observable<void> {
    return this.http.put<void>('api/vehicles/second-compartment-low-temperature-change/' + vehicleId + `?temperature=${input}`, {});
  }

  public saveSecondCompartmentHighTemperature(vehicleId: number, input: string): Observable<void> {
    return this.http.put<void>('api/vehicles/second-compartment-high-temperature-change/' + vehicleId + `?temperature=${input}`, {});
  }

  public saveLowFuel(vehicleId: number, input: string): Observable<any> {
    return this.http.put('api/vehicles/low-fuel-change/' + vehicleId + `?lowFuel=${input}`, {}, {responseType: 'text'});
  }

  public saveCo2Coefficient(vehicleId: number, coefficient: string): Observable<string> {
    return this.http.put(`api/vehicles/co2-coefficient?vehicle=${vehicleId}&coefficient=${coefficient}`, {}, {responseType: 'text'})
  }

  public fetchTabConf(vehicleId: number): Observable<TabClientConfigurationEntry> {
    return this.http.get<TabClientConfigurationEntry>(`/api/tab-conf/vehicle-configuration?vehicle=${vehicleId}`)
  }

  public switchEco(vehicleId: number, state: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-eco', {}, {params: params});
  }

  public switchEcoDriver(vehicleId: number, ecoDriver: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'ecoDriver', ecoDriver);
    return this.http.post<void>('/api/tab-conf/switch-eco-driver', {}, {params: params});
  }

  public switchTacho(vehicleId: number, state: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-tacho', {}, {params: params});
  }

  public switchForms(vehicleId: number, state: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-forms', {}, {params: params});
  }

  public switchDocs(vehicleId: number, state: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-docs', {}, {params: params});
  }

  public switchFreezer(vehicleId: number, state: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-freezer', {}, {params: params});
  }

  public switchInProgress(vehicleId: number, state: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-in-progress', {}, {params: params});
  }

  public switchFinishing(vehicleId: number, state: boolean): Observable<void>  {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-finishing', {}, {params: params});
  }

  public switchArrived(vehicleId: number, state: boolean): Observable<void>  {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-arrived', {}, {params: params});
  }

  public switchWaiting(vehicleId: number, state: boolean): Observable<void>  {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-waiting', {}, {params: params});
  }

  public switchTripDocuments(vehicleId: number, state: boolean): Observable<void>  {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-trip-documents', {}, {params: params});
  }

  public switchInstructions(vehicleId: number, state: boolean): Observable<void>  {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-instructions', {}, {params: params});
  }

  public switchDriverActivities(vehicleId: number, state: boolean): Observable<void>  {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-driver-activities', {}, {params: params});
  }

  public switchRestOptions(vehicleId: number, state: boolean): Observable<void>  {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-rest-options', {}, {params: params});
  }

  public saveTolerance(vehicleId: number, input: number): Observable<Response> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'tolerance', input);
    return this.http.post<Response>('/api/tab-conf/off-route-tolerance', {}, {params: params});
  }

  public clearData(vehicleId: number): Observable<Response> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    return this.http.post<Response>('/api/tab-conf/clear-data', {}, {params: params});
  }

  public switchVoiceMessages(vehicleId: number, state: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-voice-messages', {}, {params: params});
  }

  public switchTimeWatermark(vehicleId: number, state: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-time-watermark', {}, {params: params});
  }

  public switchCanChangeTaskIdx(vehicleId: number, state: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'state', state);
    return this.http.post<void>('/api/tab-conf/switch-change-task-idx', {}, {params: params});
  }

  public saveTaskIdxChangeRadius(vehicleId: number, radius: number): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'radius', radius);
    return this.http.post<void>('/api/tab-conf/task-idx-change-radius', {}, {params: params});
  }

  public fetchVehicleParameters(vehicleId: number): Observable<VehicleParametersForm> {
    return this.http.get<VehicleParametersForm>(`/api/vehicle-params?vehicleId=${vehicleId}`)
  }

  public saveVehicleParameters(parameters: VehicleParametersForm): Observable<any> {
    return this.http.post<void>('/api/vehicle-params', parameters, {});
  }

  public fetchVehicleForActions(vehicleId: number): Observable<VehicleActionsEntry> {
    return this.http.get<VehicleActionsEntry>(`/api/vehicles/vehicle-actions?vehicleId=${vehicleId}`);
  }

  public createNote(form: any): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.post('/api/vehicle-notes', form, {headers: headers});
  }

  public fetchVehicleNotes(vehicleId: number): Observable<VehicleNotesEntry[]> {
    return this.http.get<VehicleNotesEntry[]>(`/api/vehicle-notes?vehicleId=${vehicleId}`);
  }

  public deleteNote(id: number): Observable<Object> {
    return this.http.delete(`/api/vehicle-notes?id=${id}`, {});
  }

  public updateNote(text: string, reminder: string, id: number): Observable<any> {
    return this.http.put(`/api/vehicle-notes`, {id, reminder, text}, {responseType: 'text'})
  }

  public fetchTempId(vehicleId: number): Observable<TempIdChangeEntry> {
    return this.http.get<TempIdChangeEntry>(`/api/temp-change-id?vehicleId=${vehicleId}`);
  }

  public updateTempIds(tempIds: Array<any>, vehicleId: number): Observable<any> {
    return this.http.put(`/api/temp-change-id?tempIds=${tempIds}&vehicleId=${vehicleId}`, {});
  }

  public deleteVehicleTablet(vehicleId: number): Observable<any> {
    return this.http.delete(`/api/tab-device/delete-vehicle-tablet?vehicleId=${vehicleId}`, {});
  }

  public checkUniqueTabletImei(vehicleId: number, imei: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/tab-device/check-tablet-imei?vehicleId=${vehicleId}&imei=${imei}`, {});
  }

  public checkUniqueTabletPhoneNumber(vehicleId: number, phoneNumber: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/tab-device/check-phone-number?vehicleId=${vehicleId}&phoneNumber=${phoneNumber}`, {});
  }

  public addTabletForVehicle(form: any, vehicleId: number): Observable<any> {
    return this.http.post(`/api/tab-device/add-new-tablet?vehicle=${vehicleId}`, form);
  }

  public updateEngineHours(newEngineHours: number, vehicleId: number): Observable<any> {
    return this.http.put(`/api/engine-hours?engineHours=${newEngineHours}&vehicleId=${vehicleId}`, {});
  }

  public fetchVehicleData(vehicleId: number): Observable<VehicleData> {
    return this.http.get<VehicleData>(`/api/vehicles/vehicle-info-data?vehicleId=${vehicleId}`);
  }

  public updateVirtualOdometer(newVirtualOdometer: number, vehicleId: number): Observable<any> {
    return this.http.put(
      `/api/vehicles/processor-data/virtual-odometer?newVirtualOdometer=${newVirtualOdometer}&vehicleId=${vehicleId}`,
      {}
      );
  }

  public getLatestVehicle(vehicleId: number): Observable<LatestVehicleEntry> {
    return this.http.get<LatestVehicleEntry>(`/api/vehicles/${vehicleId}/latest`);
  }

  public createDriverNotification(form: any): Observable<void> {
    return this.http.post<void>('/api/driver-notifications', form);
  }

  public fetchDriverNotifications(vehicleId: number): Observable<DriverNotificationsEntry[]> {
    return this.http.get<DriverNotificationsEntry[]>(`/api/driver-notifications?vehicleId=${vehicleId}`);
  }

  public deleteDriverNotification(id: number, vehicleId: number): Observable<void> {
    return this.http.delete<void>(`/api/driver-notifications?id=${id}&vehicleId=${vehicleId}`);
  }

  public updateDriverNotification(id: number, form: DriverNotificationsForm): Observable<void> {
    return this.http.put<void>(`/api/driver-notifications/${id}`, form);
  }

  public triggerUpdateNoteStatus(data: any): void {
    this.updateNoteStatusSource.next(data);
  }

  public updateNoteStatus(): Observable<any> {
    return this.updateNoteStatus$;
  }

  public getVehicleAllowedEmployees(vehicleId: number): Observable<number[]> {
    return this.http.get<number[]>(`/api/vehicle-visibility/${vehicleId}/employees`);
  }

  public upsertVisibility(vehicleId: number, employeesIds: number[]): Observable<void> {
    return this.http.put<void>(`/api/vehicle-visibility/${vehicleId}`, employeesIds);
  }

  public getNotVisibleVehicles(): Observable<number[]> {
    return this.http.get<number[]>(`/api/vehicle-visibility/not-allowed`, {headers: {skip: 'true'}});
  }

  public saveVehicleInventory(form: VehicleInventoryForm): Observable<void> {
    return this.http.post<void>('/api/inventory', form);
  }

  public getInventoryItems(vehicleId: number): Observable<InventoryItemForm[]> {
    return this.http.get<InventoryItemForm[]>(`/api/inventory?vehicleId=${vehicleId}`);
  }

  public getAvailableMaps(): Observable<AvailableMapEntry[]> {
    return this.http.get<AvailableMapEntry[]>('/api/navigation/available-maps');
  }

  public getTabMaps(imei: string, detached: boolean): Observable<number[]> {
    return this.http.get<number[]>(`/api/navigation/tab-maps?imei=${imei}&detached=${detached}`);
  }

  public updateTabMaps(imei: string, detached: boolean, maps: number[]): Observable<void> {
    return this.http.post<void>(`/api/navigation/update-tab-maps?imei=${imei}&detached=${detached}`, maps);
  }

  public initiateMapUpdate(imei: string, detached: boolean): Observable<void> {
    return this.http.post<void>(`/api/navigation/initiate-map-update?imei=${imei}&detached=${detached}`, {});
  }

  public getFloatsCalibration(vehicleId: number): Observable<string[]> {
    return this.http.get<string[]>(`/api/float-calibration?vehicleId=${vehicleId}`, {})
  }

  public deleteFloatCalibration(vehicleId: number, isFloatCalibration1: boolean): Observable<void> {
    return this.http.delete<void>(`/api/float-calibration?vehicleId=${vehicleId}&isFloatCalibration1=${isFloatCalibration1}`);
  }

  public importFloatCalibrationExcel(vehicleId: number, isFloatCalibration1: boolean, formData: FormData): Observable<void> {
    return this.http.post<void>(`/api/float-calibration/import?vehicleId=${vehicleId}&isFloatCalibration1=${isFloatCalibration1}`,
      formData,
      {headers: {skip: 'true'}});
  }

  public getTellTaleByDeviceKey(vehicleId: number, deviceKey: string): Observable<TellTaleEntry> {
    return this.http.get<TellTaleEntry>(`/api/vehicles/${vehicleId}/tell-tale?deviceKey=${deviceKey}`);
  }

  public getTrackTellTale(vehicleId: number, issuedOn: any) {
    return this.http.get<TellTaleEntry>(`/api/vehicles/${vehicleId}/track-tell-tale?issuedOn=${issuedOn}`)
  }

  // -------------- Payment cards ----------------

  public savePaymentCard(form: PaymentCardForm): Observable<void> {
    return this.http.post<void>('/api/payment-card', form);
  }

  public getPaymentCardsByVehicle(vehicleId: number): Observable<PaymentCardEntry[]> {
    return this.http.get<PaymentCardEntry[]>(`/api/payment-card/by-vehicle?vehicleId=${vehicleId}`);
  }

  public updatePaymentCard(form: PaymentCardForm, cardId: number): Observable<void> {
    return this.http.put<void>(`/api/payment-card/${cardId}`, form);
  }

  public deletePaymentCard(cardId: number): Observable<void> {
    return this.http.delete<void>(`/api/payment-card/${cardId}`);
  }

  public getCardStatus(cardId: number): Observable<ShellCardEntry> {
    return this.http.get<ShellCardEntry>(`/api/payment-card/${cardId}/shell-status`);
  }

  public blockCard(cardId: number, block: boolean): Observable<ShellCardResponse> {
    return this.http.get<ShellCardResponse>(`/api/payment-card/${cardId}/shell-block?block=${block}`);
  }

  public updateApk(vehicleId: number): Observable<void> {
    return this.http.post<void>('/api/tab-conf/update-apk', vehicleId);
  }

}
