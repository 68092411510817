import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {PagesComponent} from './pages.component';
import {IsLoggedIn} from '../core/auth/is-logged-in';
import {IndexComponent} from './index.component';
import {
  PartnerPositionTrackComponent
} from './client/partners/positions/partner-position-track/partner-position-track.component';

export const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [IsLoggedIn],
    children: [
      {
        path: '',
        component: IndexComponent
      },
      {
        path: 'monitor',
        loadChildren: () => import('app/pages/client/monitor/monitor.module').then(m => m.MonitorModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'profile',
        loadChildren: () => import('app/pages/shared/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'groups',
        loadChildren: () => import('app/pages/client/group/group.module').then(m => m.GroupModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'partners',
        loadChildren: () => import('app/pages/client/partners/partners.module').then(m => m.PartnersModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'partners-employees',
        loadChildren: () => import('app/pages/client/partners/employee/client-partner-employees.module')
          .then(m => m.ClientPartnerEmployeesModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'partners-docs',
        loadChildren: () => import('app/pages/client/partners/documents/partner-doc.module')
          .then(m => m.PartnerDocModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'shared-vehicles',
        loadChildren: () => import('app/pages/client/partners/vehicles/partner-vehicles.module')
          .then(m => m.PartnerVehiclesModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'colleagues',
        loadChildren: () => import('app/pages/client/clients/employee/client-employees.module')
          .then(m => m.ClientEmployeesModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'department',
        loadChildren: () => import('app/pages/client/clients/department/department.module')
          .then(m => m.DepartmentModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'drivers',
        loadChildren: () => import('app/pages/client/drivers/drivers.module').then(m => m.DriversModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'vehicles',
        loadChildren: () => import('app/pages/client/vehicles/vehicles.module').then(m => m.VehiclesModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'configs/data',
        loadChildren: () => import('app/pages/client/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'alarms',
        loadChildren: () => import('app/pages/client/alarms/alarms/alarms.module').then(m => m.AlarmsModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'notices',
        loadChildren: () => import('app/pages/client/alarms/notices/notices.module').then(m => m.NoticesModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'personal',
        loadChildren: () => import('app/pages/client/alarms/personal/personal-notifications.module')
          .then(m => m.PersonalNotificationsModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'carrier-vehicles',
        loadChildren: () => import('app/pages/partner/shared-vehicles/shared-vehicles.module')
          .then(m => m.SharedVehiclesModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'cooworkers',
        loadChildren: () => import('app/pages/partner/employee/partner-employees.module')
          .then(m => m.PartnerEmployeesModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'reports',
        loadChildren: () => import('app/pages/client/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'service',
        loadChildren: () => import('app/pages/client/service/service.module').then(m => m.ServiceModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'geo-zones',
        loadChildren: () => import('app/pages/client/geo-zones/geo-zones.module').then(m => m.GeoZonesModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'poi',
        loadChildren: () => import('app/pages/client/poi/poi.module').then(m => m.POIModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'marked-roads',
        loadChildren: () => import('app/pages/client/marked-roads/marked-roads.module').then(m => m.MarkedRoadsModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'documents',
        loadChildren: () => import('app/pages/client/documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'orders',
        loadChildren: () => import('app/pages/client/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'tasks',
        loadChildren: () => import('app/pages/client/tasks/tasks.module').then(m => m.TasksModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'shared-positions',
        loadChildren: () => import('app/pages/client/partners/positions/partner-positions.module')
          .then(m => m.PartnerPositionsModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'planning',
        loadChildren: () => import('app/pages/client/planning/planning.module').then(m => m.PlanningModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'calendar',
        loadChildren: () => import('app/pages/client/calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [IsLoggedIn]
      },
      {
        path: 'route',
        loadChildren: () => import('app/pages/client/route/route.module').then(m => m.RouteModule),
        canActivate: [IsLoggedIn]
      }
    ]
  },
  {
    path: 'shared-positions/:uuid/track',
    component: PartnerPositionTrackComponent,
  }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
