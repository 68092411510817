import {ChatConfigsForm} from '../../pages/client/settings/global-config/chat-configs/model/chat-configs.form';
import {MapConfigsForm} from '../../pages/client/settings/global-config/map-configs/map-configs.form';
import {PopupConfigsForm} from '../../pages/client/settings/global-config/popup-configs/popup-configs.form';
import {
  TachoTableConfigsForm
} from '../../pages/client/settings/global-config/tacho-table-configs/form/tacho-table-configs.form';
import {TasksConfigsForm} from '../../pages/client/settings/global-config/tasks-configs/model/tasks-configs.form';
import {
  TrackPopupConfigsForm
} from '../../pages/client/settings/global-config/track-popup-configs/model/track-popup-configs.form';
import {WarningsConfigsForm} from '../../pages/client/settings/global-config/warnings-configs/warnings-configs.form';
import {ControlPanelSettingsEntry} from './control-panel-settings.entry';
import {IdValue} from './id-value';

export enum Role {
  ROLE_CLIENT_EMPLOYEE_ADMIN,
  ROLE_CLIENT_EMPLOYEE_MANAGER,
  ROLE_CLIENT_ENGINEER,
  ROLE_PARTNER_EMPLOYEE,
  ROLE_CLIENT_SENIOR_MANAGER
}

export enum Restrictions {
  NO_RESTRICTIONS, WARNING, RESTRICTIONS_ON_USE
}

export class Module {
  public static HAS_FREEZERS = 'hasFreezers';
  public static HEAVY_TRACK = 'heavyTrackModule';
  public static VIDEO = 'videoMonitorModule';
  public static MANAGEMENT = 'managementModule';
  public static ALARM = 'alarmsModule';
  public static ORDERS = 'ordersModule';
  public static ORDER_READER = 'orderReaderModule';
  public static VIEW_TASKS = 'viewTasksModule';
  public static VIEW_REPORT = 'viewReportModule';
  public static DOCS = 'docsModule'
  public static TACHO_ANALYTICS = 'tachoAnalyticsModule';
  public static NAVIGATION = 'navigationModule';
  public static PLANNING = 'planningModule';
  public static CALENDAR = 'calendarModule';
  public static TRAILER_SWAP = 'trailerSwapModule';
  public static TELL_TALE = 'tellTaleModule';
  public static IMI = 'imiModule';
  public static AI_ROUTING = 'aiRoutingModule';
}

export interface Profile {
  id: number;
  email: string;
  fullName: string;
  phoneNumber: string;
  role: Role;
  modules: string [];
  clientHQ: string;
  logoPath: string;
  company: IdValue<String>;

  showInstructions?: boolean;
  restrictions?: Restrictions;
  activeDirectoryUser?: boolean;

  vehicleGroup?: any;
  contractor?: boolean;
  department?: number;
  crtPartner?: boolean;
  forbiddenManageTasks?: boolean;
  lkwActive?: boolean;
  unveroActive?: boolean;

  chatConfigs?: ChatConfigsForm;
  mapConfigs?: MapConfigsForm;
  popupConfigs?: PopupConfigsForm;
  tachoConfigs?: TachoTableConfigsForm;
  tasksConfigs?: TasksConfigsForm;
  trackPopupConfigs?: TrackPopupConfigsForm;
  warningsConfigs?: WarningsConfigsForm;
  controlPanelSettings?: ControlPanelSettingsEntry;
}

export function isPartner(role: string) {
  return role === Role[Role.ROLE_PARTNER_EMPLOYEE];
}

export function isClient(role: string) {
  return role === Role[Role.ROLE_CLIENT_EMPLOYEE_ADMIN]
    || role === Role[Role.ROLE_CLIENT_EMPLOYEE_MANAGER]
    || role === Role[Role.ROLE_CLIENT_ENGINEER]
    || role === Role[Role.ROLE_CLIENT_SENIOR_MANAGER];
}

export function canManageServices(role: string) {
  return role === Role[Role.ROLE_CLIENT_EMPLOYEE_ADMIN]
    || role === Role[Role.ROLE_CLIENT_ENGINEER];
}
