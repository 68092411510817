import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthStorage} from '../../../core/auth/auth-storage';
import {Router} from '@angular/router';
import {AuthConfig} from '../../../core/auth/auth-config';
import {isPartner, Module, Profile} from '../../../core/model/profile';
import {GlobalService} from '../../../core/global/global.service';
import {SwitchClientEntry} from '../../../core/model/switch-client.entry';
import {ProfileService} from '../../../core/global/profile.service';
import {NotificationService} from '../../../core/notification/notification.service';
import {ChatService} from '../../../core/component/chat/chat.service';
import {AlarmsWebSocketService} from '../../../core/ws/alarms-web-socket.service';
import {ChatWebSocketService} from '../../../core/ws/chat-web-socket.service';
import {AssetsStateWebSocketService} from '../../../core/ws/assets-state/assets-state-web-socket.service';
import {ChatWsStateService} from '../../../core/ws/chat-ws-state.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SubscriptionUtils} from '../../../core/commons/subscription.utils';
import {TranslateService} from '@ngx-translate/core';
import {DeviceService} from '../../../core/commons/device.service';
import {Userpilot} from 'userpilot';
import {FileUtils} from '../../../core/commons/file.utils';
import {environment} from '../../../../environments/environment';
import {GlobalConstants} from '../../../core/global/global.constants';

const isWebview = require("is-ua-webview");
@Component({
  selector: 'app-navbar',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit, OnDestroy {

  public hasChat: boolean;
  public webView: boolean;
  public currentCount = 0;
  public currentFileCount = 0;
  public isClientLogged: boolean;
  public customerSupport: string[];
  public version = '9.04.07';
  public clients: SwitchClientEntry;
  public loggedUser: Profile = <Profile>{};
  public logoPath: string;
  public isMobile = false;
  public shouldShowLogo = environment.name !== 'PROD'
    || GlobalConstants.FHDomains.includes(location.hostname.split('.')[0]);

  private chatActive = false;
  private blinkInterval: any = null;
  private subSubject = new Subject<boolean>();

  constructor(private router: Router,
              private cd: ChangeDetectorRef,
              private globalService: GlobalService,
              private notification: NotificationService,
              private profileService: ProfileService,
              private alarmsWSService: AlarmsWebSocketService,
              private chatWSService: ChatWebSocketService,
              private assetsStateWsService: AssetsStateWebSocketService,
              private chatService: ChatService,
              private chatWsStateService: ChatWsStateService,
              private deviceService: DeviceService,
              private translate: TranslateService) {

  }

  public ngOnInit(): void {
    this.webView = isWebview(navigator.userAgent);
    this.reestablishSocketConnection();
    this.getLogo();
    this.getLoggedProfile();
    this.globalService.getCustomerSupport()
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        data => {
          if (data) {
            this.customerSupport = data.trim().split(' ')
          }
        },
        err => null
      );

    this.chatWsStateService.messageCount$
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        data => this.updateMessageCounts(data.textMessageCount, data.fileMessageCount)
      );
    this.isMobile = this.deviceService.isMobile();
  }

  private getLogo() {
    if (!this.shouldShowLogo) {
      this.globalService.getSellerLogo().subscribe(data =>
        this.logoPath = FileUtils.generateImgUrl(data)
      );
    } else {
      this.logoPath = 'assets/img/logo/fleethand-logo--ua.svg';
    }
  }

  private getLoggedProfile() {
    this.globalService.getLoggedProfile().subscribe(
      data => {
        this.loggedUser = data;
        this.hasChat = this.loggedUser.modules.indexOf(Module.MANAGEMENT) > -1;
        if (this.isPartner()) {
          this.getPartnerClients();
          this.isClientLogged = false;
        } else {
          this.isClientLogged = true;
        }
      },
      err => this.notification.loadingError()
    );
  }

  public isPartner() {
    return this.loggedUser.role && isPartner(this.loggedUser.role.toString());
  }

  public getDefaultPage(): void {
    this.isPartner()
      ? this.router.navigate([AuthConfig.PARTNER_DEFAULT_PAGE])
      : this.router.navigate([AuthConfig.CLIENT_MAIN_PAGE]);
  }

  private getPartnerClients(): void {
    this.profileService.clients().subscribe(
      data => this.clients = data,
      err => this.notification.loadingError()
    );
  }

  public logout() {
    this.closeSocketConnections();
    this.chatService.closeChat();
    let appearance = AuthStorage.getAppearance();
    localStorage.clear();
    AuthStorage.setAppearance(appearance);
    this.router.navigate([AuthConfig.LOGIN_ROUTE]);
  }

  public switchProfile(clientId: number) {
    this.profileService.switchProfile(clientId).subscribe(
      data => {
        AuthStorage.persistTokens(data.accessToken, data.refreshToken);
        AuthStorage.persistClientId(clientId);
        this.getLoggedProfile();
        this.notification.updatingSuccess();
        this.chatService.closeChat();
        this.reestablishSocketConnection();
        this.router.navigate(['/']);
      },
      err => this.notification.storingError()
    );
  }

  public handleSupportLine($event: MouseEvent) {
    let wiki = document.getElementById('wiki');
    let instruction = document.getElementById('instruction');
    let path = $event.composedPath();
    if (path.indexOf(wiki) === -1 && path.indexOf(instruction) === -1) {
      $event.stopPropagation();
    }
  }

  public toggleChat() {
    this.chatActive = !this.chatActive;
    this.chatService.triggerChatToggle(this.chatActive);
  }

  public updateMessageCounts(count: number, fileCount: number): void {
    if (count === 0 && fileCount === 0) {
      this.stopBlinkink();
    }
    const newTextMessages = this.currentCount === 0 && count > 0;
    const newFileMessages = this.currentFileCount === 0 && fileCount > 0;
    if (this.blinkInterval == null && (newTextMessages || newFileMessages)) {
      this.startBlinkink();
    }
    if ((this.currentCount + this.currentFileCount) < count) {
      this.chatService.playMessageSound();
    }
    this.currentCount = count;
    this.currentFileCount = fileCount;
  }

  public triggerUserPilot() {
    Userpilot.trigger('resource_center:LT83Apxpvo');
  }

  private startBlinkink(): void {
    this.blinkInterval = setInterval(() => {
      const totalCount = this.currentCount + this.currentFileCount;
      document.title = '('
        + totalCount
        + ') '
        + this.translate.instant('chat.messages.title');
      setTimeout(() => document.title = 'Fleethand', 1000);
    }, 2000);
  }

  private stopBlinkink(): void {
    clearInterval(this.blinkInterval);
    this.blinkInterval = null;
    document.title = 'Fleethand';
  }

  private reestablishSocketConnection() {
    this.closeSocketConnections();
    this.chatWSService.getUserAndConnect();
    this.alarmsWSService.getUserAndConnect();
    this.assetsStateWsService.getUserAndConnect();
  }

  private closeSocketConnections() {
    this.alarmsWSService.disconnect();
    this.chatWSService.disconnect();
    this.assetsStateWsService.disconnect();
  }

  public ngOnDestroy(): void {
    if (this.logoPath != null) {
      URL.revokeObjectURL(this.logoPath);
    }
    clearInterval(this.blinkInterval);
    SubscriptionUtils.safeUnsubscribeSubject(this.subSubject)
  }

}
