import OlXYZ from 'ol/source/XYZ';
import TileWMS from 'ol/source/TileWMS';
import OlTileLayer from 'ol/layer/Tile';
import {Group as LayerGroup} from 'ol/layer';
import {fromLonLat} from 'ol/proj';

export class MapSettings {

  public static MAP_ADDRESS_SEARCH_INDEX = 0;
  public static MAP_LEGEND_INDEX = 2;
  public static MAP_POI_GROUPS_INDEX = 1;
  public static MAP_FUEL_STATION_INDEX = 3;

  public static ZOOM = 5;
  public static LAT = 51.678418;
  public static LNG = 7.809007;
  public static MINLAT = -85;
  public static MAXLAT = 85;
  public static MINLNG = -180;
  public static MAXLNG = 180;
  public static MIN_ZOOM_PIXEL_STEP = 128000;

  public static POI_MARKER = {
    OPACITY: 1,
    STROKE_WEIGHT: 0.5,
    STROKE_COLOR: '#000000',
    Z_INDEX: 1000,
    SCALE: 4
  };

  public static POI_COLORS = ['#F44336', '#673AB7', '#03A9F4', '#4CAF50', '#FF9800', '#607D8B',
    '#9C27B0', '#009688', '#795548', '#E91E63', '#8BC34A', '#FFC107'];

  public static ICONS = {
    truck: {
      MOVING: 'assets/img/vehicle/moving.svg',
      NOT_MOVING: 'assets/img/vehicle/not-moving.svg',
      ENGINE_ON: 'assets/img/vehicle/engine-on.svg',
      OFFLINE: 'assets/img/vehicle/offline.svg',
      ALERT: 'assets/img/vehicle/alert.svg',
      MOVING_WARNING: 'assets/img/vehicle/moving_warning.svg',
      NOT_MOVING_WARNING: 'assets/img/vehicle/not-moving_warning.svg',
      ENGINE_ON_WARNING: 'assets/img/vehicle/engine-on_warning.svg',
      OFFLINE_WARNING: 'assets/img/vehicle/offline_warning.svg',
      IDLE: 'assets/img/vehicle/idle.svg',
      LOADING_UNLOADING_DELAY: 'assets/img/vehicle/loading_unloading_delay.svg'
    },
    car: 'assets/img/car_icon.png',
    alert: 'assets/img/app/alert.png',
    task: {
      destination: 'assets/img/task/map/destination.svg',
      waypoint: 'assets/img/task/map/waypoint.svg',
      generatedWaypoint: 'assets/img/task/map/generated_waypoint.svg',
      origin: 'assets/img/task/map/origin.svg',
      point: 'assets/img/marker/addr_search.svg'
    },
    route: {
      ACTIVE: '<i class="ico-route fa-lg" style="color: rgba(48, 91, 154, 1);"></i>',
      HISTORY: '<i class="ico-route fa-lg" style="color: #3389c2;"></i>',
      NOT_CONFIRMED: '<i class="ico-route fa-lg" style="color: #D2593F;"></i>',
      PLANNED: '<i class="ico-route fa-lg" style="color: #5ab424;"></i>',
      DROVE: '<i class="ico-route fa-lg" style="color: #1b5e20;"></i>'
    },
    form: {
      DRAGGABLE: '<i class="ico-route fa-lg" style="color: #73ABF4;"></i>',
      PLANNED: '<i class="ico-route fa-lg" style="color: #D2593F;"></i>',
    },
    queue: {
      NOT_CONFIRMED: '<i class="ico-route fa-lg" style="color: rgb(196, 0, 23);"></i>',
      PLANNED: '<i class="ico-route fa-lg" style="color: rgb(117, 148, 5);"></i>',
      DROVE: '<i class="ico-route fa-lg" style="color: rgb(27, 94, 32);"></i>'
    },
    swapSelect: {
      FIRST_EXECUTING: '<i class="ico-route fa-lg" style="color: rgb(117, 148, 5);"></i>',
      FIRST_PLANNED: '<i class="ico-route fa-lg" style="color: rgb(196, 0, 23);"></i>',
      SECOND_EXECUTING: '<i class="ico-route fa-lg" style="color: rgb(138, 190, 11);"></i>',
      SECOND_PLANNED: '<i class="ico-route fa-lg" style="color: rgb(236, 51, 7);"></i>',
      SWAP_POINT: '<i class="fa fa-circle-o fa-lg" style="color: rgba(46,143,215,0.7);"></i>'
    },
    doneTasks: {
      PLANNED: '<i class="ico-route fa-lg" style="color: rgb(117, 148, 5);"></i>',
      DROVE: '<i class="ico-route fa-lg" style="color: rgb(27, 94, 32);"></i>'
    },
    fuelLevel: {
      FULL: 'assets/img/fuel/FUEL100.svg',
      ALMOST_FULL: 'assets/img/fuel/FUEL75.svg',
      HALF: 'assets/img/fuel/FUEL50.svg',
      LOW: 'assets/img/fuel/FUEL25.svg',
      FULL_SCHEDULED: 'assets/img/fuel/FUEL_SCHEDULED100.svg',
      ALMOST_FULL_SCHEDULED: 'assets/img/fuel/FUEL_SCHEDULED75.svg',
      HALF_SCHEDULED: 'assets/img/fuel/FUEL_SCHEDULED50.svg',
      LOW_SCHEDULED: 'assets/img/fuel/FUEL_SCHEDULED25.svg'
    },
    proTasks: {
      ACTIVE: '<i class="ico-route fa-lg" style="color: rgba(48, 91, 154, 1);"></i>',
      PASSIVE: '<i class="ico-route fa-lg" style="color: rgba(48, 91, 154, 0.5);"></i>',
      ALTERNATIVE: '<i class="ico-route fa-lg" style="color: #6E9DD3;"></i>'
    }
  };

  public static getMapTypes() {
    let mapTypeIds = [];
    for (let type of Object.keys(google.maps.MapTypeId)) {
      mapTypeIds.push(google.maps.MapTypeId[type]);
    }
    mapTypeIds.push('OSM');
    mapTypeIds.push('Truck');

    return mapTypeIds;
  }

  public static addMapType(map, name, tileUrl) {
    map.mapTypes.set(name, new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        let url = tileUrl
          .replace('{x}', coord.x)
          .replace('{y}', coord.y)
          .replace('{z}', zoom);
        return url;
      },
      tileSize: new google.maps.Size(256, 256),
      name: name,
      maxZoom: 26
    }));
  }

  public static getOLayers() {
    return [
      new OlTileLayer({
        visible: true,
        preload: 2,
        imagerySet: 'google',
        source: new OlXYZ({
          url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en',
          crossOrigin: 'Anonymous',
          cacheSize: 256,
          transition: 200,
          tileLoadFunction: (tile, src) => {
            tile.getImage().src = src
          }
        })
      }),
      new OlTileLayer({
        visible: false,
        preload: 2,
        imagerySet: 'google-satellite',
        source: new OlXYZ({
          url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&hl=en',
          crossOrigin: 'Anonymous',
          cacheSize: 256,
          transition: 200,
          tileLoadFunction: (tile, src) => {
            tile.getImage().src = src
          }
        })
      }),
      new OlTileLayer({
        visible: false,
        preload: 2,
        imagerySet: 'google-traffic',
        source: new OlXYZ({
          url: 'https://mt0.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}&hl=en',
          crossOrigin: 'Anonymous',
          cacheSize: 256,
          transition: 200,
          tileLoadFunction: (tile, src) => {
            tile.getImage().src = src
          }
        })
      }),
      new OlTileLayer({
        visible: false,
        preload: 2,
        imagerySet: 'google-satellite-traffic',
        source: new OlXYZ({
          url: 'https://mt1.google.com/vt/lyrs=y,traffic&x={x}&y={y}&z={z}&hl=en',
          crossOrigin: 'Anonymous',
          cacheSize: 256,
          transition: 200,
          tileLoadFunction: (tile, src) => {
            tile.getImage().src = src
          }
        })
      }),
      new OlTileLayer({
        visible: false,
        preload: 2,
        imagerySet: 'osm',
        source: new OlXYZ({
          url: 'https://tile.osm.org/{z}/{x}/{y}.png',
          crossOrigin: 'Anonymous',
          cacheSize: 256,
          transition: 200,
          tileLoadFunction: (tile, src) => {
            tile.getImage().src = src
          }
        })
      }),
      new OlTileLayer({
        visible: false,
        preload: 2,
        imagerySet: 'truck',
        source: new OlXYZ({
          url: 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?features=vehicle_restrictions:active_and_inactive'+
            '&style=explore.day&apiKey=J8ZkU_2y4MOmXdtBvHGTs9NSxpw-sqHTFtr-dnsk0KE',
          crossOrigin: 'Anonymous',
          cacheSize: 256,
          transition: 200,
          tileLoadFunction: (tile, src) => {
            tile.getImage().src = src
          }
        }),
      }),
      new LayerGroup({
        visible: false,
        preload: 2,
        imagerySet: 'google-street-view',
        layers: [
          new OlTileLayer({
            source: new OlXYZ({
              url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en',
              crossOrigin: 'Anonymous',
              cacheSize: 256,
              transition: 200,
              tileLoadFunction: (tile, src) => {
                tile.getImage().src = src
              }
            }),
          }),
          new OlTileLayer({
            source: new OlXYZ({
              url: 'https://mts2.google.com/mapslt?lyrs=svv&x={x}&y={y}&z={z}&hl=en&style=5,0',
              crossOrigin: 'Anonymous',
              cacheSize: 256,
              transition: 200,
              tileLoadFunction: (tile, src) => {
                tile.getImage().src = src
              }
            }),
          }),
        ]
      }),
      new OlTileLayer({
        visible: true,
        preload: 2,
        imagerySet: 'google-lt-forests',
        source: new OlXYZ({
          url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en',
          crossOrigin: 'Anonymous',
          cacheSize: 256,
          transition: 200,
          tileLoadFunction: (tile, src) => {
            tile.getImage().src = src
          }
        })
      }),
      new OlTileLayer({
        visible: false,
        preload: Infinity,
        imagerySet: 'google-lt-forests',
        source: new TileWMS({
          url: '/geoportal-wms',
          params: {
            'SERVICE': 'WMS',
            'VERSION': '1.1.1',
            'REQUEST': 'GetMap',
            'LAYERS': '0,8',
            'STYLES': '',
            'FORMAT': 'image/png',
            'TRANSPARENT': true,
            'SRS': 'EPSG:4326',
            'BBOX': '20.863790,53.861239,26.919346,56.453562',
          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous',
        }),
        opacity: 0.7
      }),
    ];
  }

  public static getMapExtent() {
    let min = fromLonLat([MapSettings.MINLNG, MapSettings.MINLAT]);
    let max = fromLonLat([MapSettings.MAXLNG, MapSettings.MAXLAT]);
    return [min[0], min[1], max[0], max[1]];
  }
}
