import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StationPrice} from '../../../core/model/station-price';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PoiCrtStationsService {

  constructor(private http: HttpClient) {
  }

  public stationPrices(stationIds: string []): Observable<StationPrice[]> {
    return this.http.post<StationPrice[]>(`/api/crt-prices`, stationIds, {headers: {skip: 'true'}});
  }

}
