export const LAST_SHOWN_TRACK = 'lastShownTrack';
export const TRACK_FILTER_FROM = 'trackFilterFrom';
export const TRACK_FILTER_TILL = 'trackFilterTill';
export const TRACK_FILTER_PERIOD = 'trackFilterPeriod';

export const SELECTED_POI = 'selectedPois';
export const SELECTED_FUEL_STATIONS = 'selectedFuelStations';
export const SELECTED_ROADS = 'selectedRoads';
export const ROUTE_AVERAGE_SPEED = 'route_use_average_speed';
export const SHOW_ALL = 'showAll';
export const DISPATCHER_MODE = 'dispatcherMode';
export const MAP_LAYER = 'selectedMapLayer';
export const CONTROL_PANEL_WIDTH = 'control-panel-width';
export const FM_LANG = 'fm-lang';
export const ORDERS_COLUMNS = 'orders-columns';

export const TASKS_ALTERNATIVES = 'tasks_alternatives';
export const TASKS_INCIDENTS = 'tasks_incidents';
export const TASKS_TRAFFIC = 'tasks_traffic';
export const TASKS_VEHICLE_HEIGHT = 'task_vehicle_height';
export const TASKS_VEHICLE_WEIGHT = 'task_vehicle_weight';
export const TASKS_VEHICLE_WIDTH = 'task_vehicle_width';
export const TASKS_AVOIDS = 'task_avoids';
export const TASKS_VEHICLE_TYPE = 'task_vehicle_type';
export const TASKS_SEND_MESSAGE = 'task_send_message';
export const TASK_STRATEGY = 'task_strategy';
export const TASK_EMISSION_TYPE = 'task_emission_type';
export const TASKS_BAD_MARKED_ROADS = 'tasks_bad_marked_roads';
export const TASKS_CONDITIONAL_RESTRICTIONS = 'task_conditional_restrictions';
export const TASKS_EXCLUDED_COUNTRIES = 'task_excluded_countries';
export const TASKS_PARKINGS_FILTER = 'tasks_parkings_filter';
export const TASKS_FUELLING_FILTER = 'tasks_fuelling_filter';

export const SHOW_TACHO_DETAILS = 'showTachoDetails';
export const LATEST_TACHO_CHART_TYPE = 'latestTachoChartType';
export const LATEST_TACHO_CHART_DAYS = 'latestTachoChartDays';
export const LATEST_TACHO_CHART_X_ZOOM = 'latestTachoChartXZoom';
export const LATEST_TACHO_CHART_CONDENSATION = 'latestTachoChartCondensation';
