import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CookieModule} from 'ngx-cookie';
import {SorterComponent} from './page/sorter/sorter.component';
import {GlobalVehiclesService} from './global/global-vehicles.service';
import {PartnerSelectComponent} from './component/partner-employee-select/partner-select.component';
import {VehicleSelectComponent} from './component/vehicle-select/vehicle-select.component';
import {AuthService} from '../auth/auth.service';
import {DeleteConfirmDirective} from './component/confirm-popup/delete-confirm.directive';
import {ConfirmPopupComponent} from './component/confirm-popup/confirm-popup.component';
import {ProfileService} from './global/profile.service';
import {ControlMessagesComponent} from './component/control-messages/control-messages.component';
import {VideoLimitDataChangeComponent} from './component/video-data-limit-change/video-data-limit-change.component';
import {PageNotFoundComponent} from './component/page-not-found';
import {VehicleGroupService} from '../pages/client/group/vehicle-group.service';
import {SMSCommandsService} from './sms/sms.commands.service';
import {TachoChartComponent} from './component/tacho/tacho-chart/tacho-chart.component';
import {GlobalTachoService} from './global/global-tacho.service';
import {TachoTableComponent} from './component/tacho/table/tacho-table.component';
import {ResponsiveTableDirective} from './directives/responsive-table.directive';
import {NumberRangePipe} from './directives/number-range.pipe';
import {ControlPanelService} from './component/control-panel/control-panel.service';
import {ControlPanelComponent} from './component/control-panel/control-panel.component';
import {MobileFooterComponent} from './component/mobile-footer/mobile-footer.component';
import {RouterModule} from '@angular/router';
import {DirectivesModule} from '../theme/directives/directives.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ResizableModule} from 'angular-resizable-element';
import {ByteToMgPipe} from './directives/byte-to-mg.pipe';
import {CanViewDirective} from './directives/can-view.directive';
import {DeviceService} from './commons/device.service';
import {EmployeePanelComponent} from './component/employee-panel/employee-panel.component';
import {EmployeePanelService} from './component/employee-panel/employee-panel.service';
import {DriverPanelComponent} from './component/driver-panel/driver-panel.component';
import {DriverPanelService} from './component/driver-panel/driver-panel.service';
import {MapInstanceService} from './map/map-instance.service';
import {LatestVehiclesInfoService} from './component/latest-vehicles-info/latest-vehicles-info.service';
import {LanguageSelectComponent} from './component/language-select/language-select.component';
import {DirectionsService} from './direction/directions.service';
import {DirectionsStateService} from './direction/directions.state.service';
import {TimeFilterComponent} from './component/filter-period/time-filter.component';
import {DurationPipe} from './directives/duration.pipe';
import {DistancePipe} from './directives/distance.pipe';
import {EntriesPipe} from './directives/entries.pipe';
import {PricePipe} from 'app/core/directives/price.pipe';
import {MapAddressSearchComponent} from './component/map-address-search/map-address-search.component';
import {RouteComponent} from './component/route/route.component';
import {PlacesService} from './component/places/places.service';
import {NearestComponent} from './component/nearest-point/nearest.component';
import {RouteService} from './component/route/route.service';
import {MonitorMarkerService} from './map/monitor-marker.service';
import {VehicleMultiSelectComponent} from './component/vehicle-multi-select/vehicle-multi-select.component';
import {GroupService} from './component/group/group.service';
import {GroupTooltipComponent} from './component/group-tooltip/group-tooltip.component';
import {TachoComponent} from '../pages/client/monitor/tacho/tacho.component';
import {PictureModalComponent} from './component/modal/picture-modal.component';
import {MapPOIComponent} from '../pages/client/tasks/tasks-manager/tasks-poi/map-poi.component';
import {MapPOIService} from '../pages/client/tasks/tasks-manager/tasks-poi/map-poi.service';
import {IntersectionsComponent} from './component/intersections/intersections.component';
import {IntersectionsService} from './component/intersections/intersections.service';
import {DispatcherService} from './component/dispatcher/dispatcher.service';
import {EmployeeMultiselectComponent} from './component/employee-multiselect/employee-multiselect.component';
import {FormDropdownSelectComponent} from './component/form-dropdown-select/form-dropdown-select.component';
import {SingleSelectComponent} from './component/single-select/single-select.component';
import {DateTimePickerComponent} from 'app/core/component/date-time-picker/date-time-picker.component';
import {DateFormatUtils} from './component/date-time-picker/date-format.utils';
import {StringInputChangeComponent} from './component/vehicle-number-change/string-input-change.component';
import {CountryFormSelectComponent} from './component/country-select/form/country-form-select.component';
import {ChatMessagesComponent} from './component/chat/messages/chat-messages.component';
import {ChatComponent} from './component/chat/chat.component';
import {ScanListComponent} from './component/chat/scan-list/scan-list.component';
import {ChatListComponent} from './component/chat/list/chat-list.component';
import {GroupMessageComponent} from './component/chat/group-message/group-message.component';
import {GroupMessageService} from './component/chat/group-message/group-message.service';
import {ChatMessagesService} from './component/chat/messages/chat-messages.service';
import {ScanListService} from './component/chat/scan-list/scan-list.service';
import {TranslateModule} from '@ngx-translate/core';
import {MapAddressSearch2Component} from './component/map-address-search2/map-address-search-2.component';
import {RulerComponent} from './component/ruler/ruler.component';
import {MonitorPoiComponent} from '../pages/client/monitor/monitor-poi/monitor-poi.component';
import {TrackMapEventsService} from './component/track/track-map-events.service';
import {TrackMapComponent} from './component/track/track-map.component';
import {TrackDrawHandlerComponent} from './component/track-draw-handler/track-draw-handler.component';
import {CanEditDirective} from './directives/can-edit.directive';
import {OlRouteComponent} from './component/ol-route/ol-route.component';
import {OlRouteService} from './component/ol-route/ol-route.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {VehicleCheckComponent} from '../pages/open/check/vehicle/vehicle-check.component';
import {DepartmentService} from '../pages/client/clients/department/department.service';
import {DepartmentSelectComponent} from './component/department-select/department-select.component';
import {MapModalComponent} from './component/map-modal/map-modal.component';
import {LazyForDirective} from './directives/lazyFor.directive';
import {PoiModalComponent} from './component/poi-modal/poi-modal.component';
import {DocumentsService} from './component/documents/documents.service';
import {DocumentsComponent} from './component/documents/documents.component';
import {ImportantInfoComponent} from './component/important-info/important-info.component';
import {ImportantInfoService} from './component/important-info/important-info.service';
import {CountdownModule} from 'ngx-countdown';
import {OperationConfirmDirective} from './component/confirm-popup/operation-confirm.directive';
import {TrailerConnectComponent} from './component/control-panel/trailer-connect/trailer-connect.component';
import {ConnectionsService} from './component/control-panel/trailer-connect/connections.service';
import {MonitorService} from '../pages/client/monitor/monitor.service';
import {CountryFilterSelectComponent} from './component/country-select/filter/country-filter-select.component';
import {DateTimePickerChangeComponent} from './component/date-time-picker-change/date-time-picker-change.component';
import {VariablePaginationComponent} from './page/variable-pagination/variable-pagination.component';
import {
  PartnerPositionTrackComponent
} from '../pages/client/partners/positions/partner-position-track/partner-position-track.component';
import {GeoZonePanelComponent} from './component/geo-zone-panel/geo-zone-panel.component';
import {RulerProTasksComponent} from './component/ruler-pro-tasks/ruler-pro-tasks.component';
import {ClientEmployeeSelectComponent} from './component/client-employee-select/client-employee-select.component';
import {CurrencyFormSelectComponent} from './component/currency-select/currency-form-select.component';
import {VATFormSelectComponent} from './component/vat-select/vat-form-select.component';
import {ReportTableComponent} from '../pages/client/reports/task/table/multiple-vehicles-table/report-table.component';
import {MarkedRoadsComponent} from './component/marked-roads/marked-roads.component';
import {MarkedRoadsService} from './component/marked-roads/marked-roads.service';
import {
  SingleVehicleReportTableComponent
} from '../pages/client/reports/task/table/single-vehicle-table/single-vehicle-report-table.component';
import {BackButtonDirective} from './component/backButton/back-button.directive';
import {VehiclesService} from '../pages/client/vehicles/vehicles.service';
import {
  VehicleNotesListComponent
} from '../pages/client/vehicles/vehicles-list/vehicle-info/vehicle-notes/vehicle-notes-list/vehicle-notes-list.component';
import {
  VehicleNotesModalComponent
} from '../pages/client/vehicles/vehicles-list/vehicle-info/vehicle-notes/vehicle-notes-modal/vehicle-notes-modal.component';
import {
  DriverNotesListComponent
} from '../pages/client/drivers/driver-info/driver-notes-list/driver-notes-list.component';
import {
  DriverNotesModalComponent
} from '../pages/client/drivers/driver-info/driver-notes-modal/driver-notes-modal.component';
import {NotesModalComponent} from './component/control-panel/notes-modal/notes-modal.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {DriversService} from '../pages/client/drivers/drivers.service';
import {GPSMoveComponent} from '../pages/client/monitor/gps-move/gps-move.component';
import {TasksConfigsService} from '../pages/client/settings/global-config/tasks-configs/tasks-configs.service';
import {AssignFormDirective} from './directives/assign-form-directive/assign-form.directive';
import {AssignFormPopupComponent} from './directives/assign-form-directive/popup/assign-form-popup.component';
import {TasksManagerService} from '../pages/client/tasks/tasks-manager/tasks-manager.service';
import {
  VehicleSignDocumentDirective
} from './directives/vehicle-sign-document-directive/vehicle-sign-document.directive';
import {
  VehicleSignDocumentPopupComponent
} from './directives/vehicle-sign-document-directive/popup/vehicle-sign-document-popup.component';
import {
  VehicleGroupConfigsService
} from '../pages/client/reports/eco/vehicle-group-configs/vehicle-group-configs.service';
import {DepartmentMultiselectComponent} from './component/department-multiselect/department-multiselect.component';
import {BugTypeComponent} from './component/bug-type/bug-type.component';
import {AutocompleteOffDirective} from './directives/autocomplete-off.directive';
import {GeoHistoryComponent} from './component/geo-history/geo-history.component';
import {GeoHistoryStateService} from './component/geo-history/geo-history.state.service';
import {GeoHistoryService} from './component/geo-history/geo-history.service';
import {MapConfigsService} from '../pages/client/settings/global-config/map-configs/map-configs.service';
import {CustomConfirmDirective} from './component/confirm-popup/custom-confirm.directive';
import {
  DriverNotificationsModalComponent
} from '../pages/client/vehicles/drivers-notifications/modal/driver-notifications-modal.component';
import {
  DriverNotificationsListComponent
} from '../pages/client/vehicles/drivers-notifications/list/driver-notifications-list.component';
import {SpeedChartComponent} from './component/speed-chart/speed-chart.component';
import {EcoChartsComponent} from './component/speeds-chart/eco-charts.component';
import {OrderMultiselectComponent} from './component/order-multiselect/order-multiselect.component';
import {PolylineInteractionsComponent} from './component/polyline-interactions/polyline-interactions.component';
import {
  FiveStepsFormComponent
} from '../pages/client/tasks/pro-tasks-manager/modal/five-steps-form/five-steps-form.component';
import {FromTillDirective} from './directives/from-till-directive/from-till.directive';
import {FromTillPopupComponent} from './directives/from-till-directive/popup/from-till-popup.component';
import {TrimInputValueDirective} from './directives/trim-input-value.directive';
import {ShellCardPopupComponent} from './directives/shell-card-popup/shell-card-popup.component';
import {ShellCardDirective} from './directives/shell-card-popup/shell-card.directive';
import {
  TachoTableConfigsService
} from '../pages/client/settings/global-config/tacho-table-configs/tacho-table-configs.service';
import {GroupNotificationComponent} from './component/group-notification/group-notification.component';
import {GroupNotificationService} from './component/group-notification/group-notification.service';
import {ChatConfigsService} from '../pages/client/settings/global-config/chat-configs/chat-configs.service';
import {ZoneSelectComponent} from './component/zone-select/zone-select.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NotificationPopupComponent} from './notification/notification-popup/notification-popup.component';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {TapaComponent} from '../pages/client/monitor/tapa/tapa.component';
import {WarningsConfigsService} from '../pages/client/settings/global-config/warnings-configs/warnings-configs.service';
import {BasicPaginationComponent} from './page/basic-pagination/basic-pagination.component';
import {PinchZoomModule} from '@meddv/ngx-pinch-zoom';
import {FromTillMenuComponent} from './component/from-till-menu/from-till-menu.component';
import {CdkMenu, CdkMenuItem} from '@angular/cdk/menu';
import {MultiselectComponent} from './component/multiselect/multiselect.component';
import {NgClickOutsideDirective} from 'ng-click-outside2';
import {InventoryModalComponent} from './component/inventory-modal/inventory-modal.component';
import {
  VehicleLabelsModalComponent
} from '../pages/client/settings/global-config/labels-configs/modal/vehicle-labels-modal.component';
import {LabelConfigsService} from '../pages/client/settings/global-config/labels-configs/label-configs.service';
import {PoiCrtStationsService} from '../pages/client/poi/poi-crt-stations.service';
import {ViolationNoticePipe} from './directives/violation-notice.pipe';
import {EfficiencyColorPipe} from './directives/efficiency-color.pipe';
import {VehicleSelectModalComponent} from './component/vehicle-select-modal/vehicle-select-modal.component';
import {VehicleSelectModalDirective} from './component/vehicle-select-modal/vehicle-select-modal.directive';
import {ReportOrderingComponent} from '../pages/client/reports/report-ordering/report-ordering.component';
import {ReportOrderingService} from '../pages/client/reports/report-ordering/report-ordering.service';
import {ReportOrderingStateService} from '../pages/client/reports/report-ordering/report-ordering-state.service';
import {InputModifiedDirective} from './directives/input-modified.directive';
import {ParkingService} from './component/parking-form/parking.service';
import {BoschParkingFormComponent} from './component/parking-form/bosch/bosch-parking-form.component';
import {
  VehicleMultiSelectByGroupComponent
} from './component/vehicle-multi-select-by-group/vehicle-multi-select-by-group.component';
import {
  AssignViolationFormDirective
} from './directives/assign-violation-form-directive/assign-violation-form.directive';
import {
  AssignViolationFormComponent
} from './directives/assign-violation-form-directive/popup/assign-violation-form.component';
import {TravisParkingFormComponent} from './component/parking-form/travis/travis-parking-form.component';
import {PdfModalComponent} from './component/pdf-modal/pdf-modal.component';
import {CurrencyMultiselectComponent} from './component/currency-multiselect/currency-multiselect.component';
import {TaskTypeStatusPipe} from './directives/task.type.status.pipe';
import {StringTextareaChangeComponent} from './component/string-textarea-change/string-textarea-change.component';
import {TachoEchartComponent} from './model/tacho/echart/tacho-echart.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {FuelingPoiComponent} from './component/fueling-poi/fueling-poi.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    CookieModule.forChild(),
    RouterModule,
    DirectivesModule,
    InfiniteScrollModule,
    ResizableModule,
    DragDropModule,
    CountdownModule,
    MatTabsModule,
    NgSelectModule,
    MatSnackBarModule,
    PinchZoomModule,
    CdkMenu,
    CdkMenuItem,
    NgClickOutsideDirective,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    })
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    CookieModule,
    SorterComponent,
    PartnerSelectComponent,
    VehicleSelectComponent,
    VehicleSelectModalDirective,
    DeleteConfirmDirective,
    OperationConfirmDirective,
    CustomConfirmDirective,
    ControlMessagesComponent,
    VideoLimitDataChangeComponent,
    PageNotFoundComponent,
    ControlPanelComponent,
    ChatComponent,
    ChatMessagesComponent,
    GroupMessageComponent,
    ChatListComponent,
    ScanListComponent,
    TachoChartComponent,
    TachoTableComponent,
    ResponsiveTableDirective,
    NumberRangePipe,
    MobileFooterComponent,
    ByteToMgPipe,
    CanViewDirective,
    DriverPanelComponent,
    EmployeePanelComponent,
    LanguageSelectComponent,
    TimeFilterComponent,
    DurationPipe,
    DistancePipe,
    EntriesPipe,
    PricePipe,
    ViolationNoticePipe,
    RouteComponent,
    MapAddressSearchComponent,
    NearestComponent,
    VehicleMultiSelectComponent,
    TachoComponent,
    VehicleMultiSelectComponent,
    GroupTooltipComponent,
    PictureModalComponent,
    InventoryModalComponent,
    MapPOIComponent,
    EmployeeMultiselectComponent,
    FormDropdownSelectComponent,
    SingleSelectComponent,
    IntersectionsComponent,
    DateTimePickerComponent,
    StringInputChangeComponent,
    CountryFormSelectComponent,
    CurrencyFormSelectComponent,
    VATFormSelectComponent,
    MapAddressSearch2Component,
    MonitorPoiComponent,
    RulerComponent,
    CanEditDirective,
    OlRouteComponent,
    TrackMapComponent,
    TrackDrawHandlerComponent,
    DepartmentSelectComponent,
    DepartmentMultiselectComponent,
    LazyForDirective,
    PoiModalComponent,
    DocumentsComponent,
    ReportOrderingComponent,
    ImportantInfoComponent,
    TrailerConnectComponent,
    CountryFilterSelectComponent,
    DateTimePickerChangeComponent,
    VariablePaginationComponent,
    GeoZonePanelComponent,
    RulerProTasksComponent,
    ClientEmployeeSelectComponent,
    ReportTableComponent,
    MarkedRoadsComponent,
    SingleVehicleReportTableComponent,
    MarkedRoadsComponent,
    BackButtonDirective,
    VehicleNotesListComponent,
    DriverNotesListComponent,
    AssignFormDirective,
    AssignViolationFormDirective,
    VehicleSignDocumentDirective,
    BugTypeComponent,
    AutocompleteOffDirective,
    GeoHistoryComponent,
    DriverNotificationsListComponent,
    SpeedChartComponent,
    PolylineInteractionsComponent,
    OrderMultiselectComponent,
    FromTillDirective,
    TrimInputValueDirective,
    ShellCardDirective,
    GroupNotificationComponent,
    ZoneSelectComponent,
    BasicPaginationComponent,
    FromTillMenuComponent,
    MultiselectComponent,
    EfficiencyColorPipe,
    InputModifiedDirective,
    VehicleMultiSelectByGroupComponent,
    PdfModalComponent,
    CurrencyMultiselectComponent,
    TaskTypeStatusPipe,
    StringTextareaChangeComponent,
    TachoEchartComponent,
    FuelingPoiComponent
  ],
  declarations: [
    SorterComponent,
    PartnerSelectComponent,
    VehicleSelectComponent,
    VehicleSelectModalComponent,
    VehicleSelectModalDirective,
    ConfirmPopupComponent,
    DeleteConfirmDirective,
    OperationConfirmDirective,
    CustomConfirmDirective,
    ControlMessagesComponent,
    VideoLimitDataChangeComponent,
    ControlPanelComponent,
    ChatComponent,
    ChatMessagesComponent,
    GroupMessageComponent,
    ChatListComponent,
    ScanListComponent,
    PageNotFoundComponent,
    TachoChartComponent,
    TachoTableComponent,
    ResponsiveTableDirective,
    NumberRangePipe,
    MobileFooterComponent,
    ByteToMgPipe,
    CanViewDirective,
    DriverPanelComponent,
    EmployeePanelComponent,
    LanguageSelectComponent,
    TimeFilterComponent,
    DurationPipe,
    DistancePipe,
    EntriesPipe,
    PricePipe,
    ViolationNoticePipe,
    RouteComponent,
    MapAddressSearchComponent,
    NearestComponent,
    VehicleMultiSelectComponent,
    TachoComponent,
    VehicleMultiSelectComponent,
    GroupTooltipComponent,
    PictureModalComponent,
    InventoryModalComponent,
    MapPOIComponent,
    IntersectionsComponent,
    EmployeeMultiselectComponent,
    FormDropdownSelectComponent,
    SingleSelectComponent,
    IntersectionsComponent,
    DateTimePickerComponent,
    StringInputChangeComponent,
    CountryFormSelectComponent,
    CurrencyFormSelectComponent,
    VATFormSelectComponent,
    IntersectionsComponent,
    MapAddressSearch2Component,
    MonitorPoiComponent,
    RulerComponent,
    TrackMapComponent,
    CanEditDirective,
    TrackDrawHandlerComponent,
    OlRouteComponent,
    VehicleCheckComponent,
    DepartmentSelectComponent,
    DepartmentMultiselectComponent,
    MapModalComponent,
    LazyForDirective,
    PoiModalComponent,
    DocumentsComponent,
    ReportOrderingComponent,
    ImportantInfoComponent,
    TrailerConnectComponent,
    CountryFilterSelectComponent,
    DateTimePickerChangeComponent,
    VariablePaginationComponent,
    PartnerPositionTrackComponent,
    GeoZonePanelComponent,
    RulerProTasksComponent,
    ClientEmployeeSelectComponent,
    ReportTableComponent,
    MarkedRoadsComponent,
    SingleVehicleReportTableComponent,
    MarkedRoadsComponent,
    BackButtonDirective,
    VehicleNotesListComponent,
    VehicleNotesModalComponent,
    DriverNotesModalComponent,
    DriverNotesListComponent,
    NotesModalComponent,
    GPSMoveComponent,
    AssignFormDirective,
    AssignFormPopupComponent,
    AssignViolationFormDirective,
    AssignViolationFormComponent,
    VehicleSignDocumentDirective,
    VehicleSignDocumentPopupComponent,
    BugTypeComponent,
    AutocompleteOffDirective,
    GeoHistoryComponent,
    DriverNotificationsModalComponent,
    VehicleLabelsModalComponent,
    DriverNotificationsListComponent,
    SpeedChartComponent,
    PolylineInteractionsComponent,
    EcoChartsComponent,
    FiveStepsFormComponent,
    OrderMultiselectComponent,
    FromTillDirective,
    FromTillPopupComponent,
    TrimInputValueDirective,
    ShellCardDirective,
    ShellCardPopupComponent,
    GroupNotificationComponent,
    ZoneSelectComponent,
    NotificationPopupComponent,
    TapaComponent,
    BasicPaginationComponent,
    FromTillMenuComponent,
    MultiselectComponent,
    EfficiencyColorPipe,
    InputModifiedDirective,
    BoschParkingFormComponent,
    VehicleMultiSelectByGroupComponent,
    TravisParkingFormComponent,
    PdfModalComponent,
    CurrencyMultiselectComponent,
    TaskTypeStatusPipe,
    StringTextareaChangeComponent,
    TachoEchartComponent,
    FuelingPoiComponent
  ],
  providers: [
    GlobalVehiclesService,
    GlobalTachoService,
    AuthService,
    VehicleGroupService,
    ProfileService,
    SMSCommandsService,
    ControlPanelService,
    GroupMessageService,
    DeviceService,
    DriverPanelService,
    ChatMessagesService,
    ScanListService,
    EmployeePanelService,
    MapInstanceService,
    LatestVehiclesInfoService,
    DirectionsService,
    DirectionsStateService,
    PlacesService,
    RouteService,
    MonitorMarkerService,
    GroupService,
    MapPOIService,
    IntersectionsService,
    DispatcherService,
    DateFormatUtils,
    OlRouteService,
    TrackMapEventsService,
    DepartmentService,
    DocumentsService,
    ReportOrderingService,
    ReportOrderingStateService,
    ImportantInfoService,
    ConnectionsService,
    MonitorService,
    DurationPipe,
    MarkedRoadsService,
    VehiclesService,
    DriversService,
    TasksManagerService,
    TasksConfigsService,
    VehicleGroupConfigsService,
    GeoHistoryStateService,
    GeoHistoryService,
    LabelConfigsService,
    MapConfigsService,
    TachoTableConfigsService,
    GroupNotificationService,
    ChatConfigsService,
    WarningsConfigsService,
    PoiCrtStationsService,
    ParkingService,
    TaskTypeStatusPipe
  ]
})
export class SharedModule {

}
